var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-stepper",
    { attrs: { value: _vm.value } },
    [
      _c(
        "v-stepper-header",
        [
          _c(
            "v-stepper-step",
            { attrs: { complete: _vm.value > 1, step: "1" } },
            [_vm._v(" Create store ")]
          ),
          _c("v-divider"),
          _c(
            "v-stepper-step",
            { attrs: { complete: _vm.value > 2, step: 2 } },
            [_vm._v(" Design ")]
          ),
          _c("v-divider"),
          _c(
            "v-stepper-step",
            { attrs: { complete: _vm.value > 3, step: 3 } },
            [_vm._v(" Build menu ")]
          ),
          _c("v-divider"),
          _c(
            "v-stepper-step",
            { attrs: { complete: _vm.value > 4, step: 4 } },
            [_vm._v(" Go live ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }