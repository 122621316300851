<template>
    <v-stepper :value="value">
        <v-stepper-header>
            <v-stepper-step
                :complete="value > 1"
                step="1"
            >
                Create store
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
                :complete="value > 2"
                :step="2"
            >
                Design
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
                :complete="value > 3"
                :step="3"
            >
                Build menu
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
                :complete="value > 4"
                :step="4"
            >
                Go live
            </v-stepper-step>
        </v-stepper-header>
    </v-stepper>
</template>

<script>
export default {
    name: "WizardStepper",
    props: {
        value: {
            type: Number,
            required: true,
        }
    }
}
</script>

<style scoped>

</style>
